import React from 'react';
import { Button, ButtonProps, styled } from '@mui/material';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: '#FF3EA5', // Brand primary color
  color: 'white',
  flexWrap: "nowrap",
  textWrap: "nowrap",
  padding: '12px 2%',
  boxSizing: "content-box",
  width: '155px',
  fontSize: '16px',
  textTransform: 'none',
  borderRadius: '8px',
  border: '2px solid white',
  '&:hover': {
    backgroundColor: 'white',
    color: '#FF3EA5',
    border: '2px solid #FF3EA5',
  },
  '& .MuiButton-endIcon': {
    marginLeft: '8px',
  },
  '&:focus': {
    outline: 'none',
    boxShadow: 'none',
  },
}));

const QuoteButton: React.FC<ButtonProps> = (props) => {
  return (
    <StyledButton href="#contact" {...props}>
      {props.children}
      {/* <RequestQuoteIcon defaultChecked color="inherit" className='pl-1 pb-1' /> */}
    </StyledButton>
  );
};

export default QuoteButton;