import React, { useRef, useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
  Rating,
  IconButton,
  useTheme,
  useMediaQuery,
  Avatar,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import one from "./../Assets/img/reviews/image-1.jpeg";
import two from "./../Assets/img/reviews/image-2.jpeg";
import three from "./../Assets/img/reviews/image-3.jpeg";
import four from "./../Assets/img/reviews/image-4.jpeg";
import five from "./../Assets/img/reviews/image-5.jpeg";

import {
  container,
  cardVariants,
} from "./Reusable/FramerMotionVariants.tsx";
import { motion } from "framer-motion";

const reviews = [
  {
    name: "Sam Wilson",
    review:
      "QuackWash went above and beyond! They were prompt, professional, and incredibly thorough. My windows have never sparkled like this before. Not only did they remove years of grime, but they also took care of every small detail. I couldn’t recommend them enough to anyone looking for top-notch service.",
    image: one,
    rating: 5,
  },
  {
    name: "Alice Gray",
    review:
      "The team at QuackWash was so meticulous and courteous. They treated my home with respect and cleaned every window to perfection. They even noticed and cleaned spots I hadn’t realized were dirty! It’s rare to find such dedicated service. Highly recommended for anyone needing reliable, high-quality window cleaning.",
    image: two,
    rating: 5,
  },
  {
    name: "John Cooper",
    review:
      "Outstanding experience from start to finish! The QuackWash staff were friendly, efficient, and professional. They took the time to explain their process and made sure I was happy with the results. My windows are spotless, and I’m thrilled with the entire experience. They truly care about customer satisfaction.",
    image: three,
    rating: 5,
  },
  {
    name: "Alex Johnson",
    review:
      "QuackWash exceeded all my expectations! They were incredibly quick without compromising on quality. My windows look fantastic, and the service was hassle-free. The team showed up on time and worked efficiently. If you need sparkling clean windows, QuackWash is the way to go.",
    image: four,
    rating: 5,
  },
  {
    name: "Chris Lee",
    review:
      "After trying multiple window cleaning services, QuackWash stands out as the best. They offer great prices without sacrificing quality. The team was very friendly, answered all my questions, and left my home looking spotless. I’ll definitely be using them again and recommending them to friends!",
    image: five,
    rating: 5,
  },
  // {
  //   name: "Pat Kim",
  //   review: "They were on time and very professional.",
  //   image: female,
  //   rating: 5,
  // },
  // {
  //   name: "Taylor Brown",
  //   review: "I will definitely use their service again.",
  //   image: female,
  //   rating: 5,
  // },
  // {
  //   name: "Jordan Davis",
  //   review: "My windows look amazing!",
  //   image: male,
  //   rating: 5,
  // },
  // {
  //   name: "Casey Martinez",
  //   review: "Highly recommend this company.",
  //   image: female,
  //   rating: 5,
  // },
];

const Reviews = () => {
  const scrollRef = useRef(null);
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const cardsPerView = isLgUp ? 3 : isMdUp ? 2 : 1;
  const cardWidth = 300;
  const cardMargin = 16; // Assuming 16px margin on each side
  const scrollDistance = cardWidth + 2 * cardMargin;

  useEffect(() => {
    if (scrollRef.current) {
      const startPosition = 0;
      scrollRef.current.scrollLeft = startPosition;
      updateArrows();
      // Attach scroll event listener
      scrollRef.current.addEventListener('scroll', updateArrows);
    }

    // Clean up the event listener when component unmounts
    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scroll', updateArrows);
      }
    };
  }, []);

  const handleScroll = (distance) => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: distance,
        behavior: "smooth",
      });
    }
  };

  const handlePrev = () => {
    handleScroll(-scrollDistance);
  };

  const handleNext = () => {
    handleScroll(scrollDistance);
  };

  const updateArrows = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      const maxScrollLeft = scrollWidth - clientWidth;

      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < maxScrollLeft - 1);
    }
  };

  return (
    <Container id="reviews" sx={{ my: 5, textAlign: "center" }}>
      <motion.div
        className="card-container"
        variants={container}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.4 }}
      >
        <motion.div className="card" variants={cardVariants}>
          <Typography variant="h4" component="h2" color="#FF3EA5" sx={{ mb: 2 }}>
            Reviews
          </Typography>
        </motion.div>
        <motion.div className="card" variants={cardVariants}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <IconButton onClick={handlePrev} sx={{ mr: 1 }} disabled={!canScrollLeft}>
              <ArrowBackIosIcon />
            </IconButton>
            <Box
              component={motion.div}
              display="flex"
              overflow="hidden"
              ref={scrollRef}
              sx={{
                py: 1,
                width: `${cardsPerView * cardWidth + (cardsPerView * 2 * cardMargin)}px`,
                scrollSnapType: "x mandatory",
                "& > *": {
                  scrollSnapAlign: "center",
                },
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                "-ms-overflow-style": "none",
                "scrollbar-width": "none",
              }}
            >
              {reviews.map((review, index) => (
                <Card
                  key={index}
                  sx={{
                    maxWidth: cardWidth,
                    mx: 2, // Convert px to theme spacing unit
                    minWidth: cardWidth,
                    boxSizing: "content-box",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    py: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "80%",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      src={review.image}
                      alt={review.name}
                      sx={{
                        width: 100,
                        height: 100,
                        mb: 2,
                      }}
                    />
                    <Box>
                      <Typography gutterBottom variant="h5" component="div">
                        {review.name}
                      </Typography>
                      <Rating
                        name={`review-rating-${index}`}
                        value={review.rating}
                        readOnly
                        precision={0.5}
                      />
                    </Box>
                  </Box>
                  <Typography variant="body2" color="text.secondary" sx={{ mx: 3 }}>
                    {review.review}
                  </Typography>
                </Card>
              ))}
            </Box>
            <IconButton onClick={handleNext} sx={{ ml: 1 }} disabled={!canScrollRight}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </motion.div>
      </motion.div>
    </Container>
  );
};

export default Reviews;