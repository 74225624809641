import React, { useState } from "react";
import { Box, Tabs, Tab, Typography, Badge, useTheme, useMediaQuery } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import WindowCleaningImage from "./../Assets/img/windowcleaner.jpg"; // Adjust the path as needed
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { motion, AnimatePresence, Variants } from "framer-motion";
import {reviewVariants, reviewContainer, containerVariants, cardVariants, container} from "./Reusable/FramerMotionVariants.tsx";

const services = [
  {
    label: "Window Cleaning",
    description: `The state of your home’s windows will have a huge effect, not only on
    the overall look of your home but also on the way the outside world looks when you’re
    inside. Keep your home looking fresh and enjoy the view clearly with our top-quality
    window cleaning service that will give you crystal clear results.`,
    features: [
      "Efficient Water-Fed Pole Cleaning",
      "Crystal Clear Results",
      "Licensed And Insured",
    ],
    image: WindowCleaningImage,
    comingSoon: false,
  },
  {
    label: "Solar Panel Cleaning",
    description: `Keep your solar panels in top condition and ensure maximum efficiency with
    our expert cleaning services.`,
    features: [
      "Maximized Efficiency",
      "Safe Cleaning Methods",
      "Licensed And Insured",
    ],
    image: WindowCleaningImage,
    comingSoon: true,
  },
  {
    label: "Pressure Washing",
    description: `Revitalize your home’s exterior with our professional pressure washing
    services.`,
    features: [
      "High-Pressure Cleaning",
      "Safe For All Surfaces",
      "Licensed And Insured",
    ],
    image: WindowCleaningImage,
    comingSoon: true,
  },
];



// Define the ServiceTabs component
const ServiceTabs = () => {
  const [selectedService, setSelectedService] = useState(0);
  const theme = useTheme();
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  // Handle tab change
  const handleChange = (event, newValue) => {
    if (!services[newValue].comingSoon) {
      setSelectedService(newValue);
    }
  };

  return (
      
      <Box id="services" sx={{ width: "100%"}}>
      <motion.div
        className="card-container"
        variants={container}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true , amount: 0.4 }}
      >
        <motion.div className="card" variants={cardVariants} >
      <Typography variant="h4" component="h2" color="#FF3EA5" sx={{ mb: 2 }}>
        Our Services
      </Typography>
      </motion.div>
        <motion.div className="card" variants={cardVariants} >
          <Tabs
            value={selectedService}
            onChange={handleChange}
            centered
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            sx={{
              mb: 3,
              mx: 3,
              ".MuiTabs-indicator": {
                backgroundColor: "#FF3EA5", // Active tab indicator color
              },
              ".MuiTab-root": {
                "&:not(.Mui-selected)": {
                  borderBottom: "2px solid lightGray",
                  ".MuiTabs-indicator": {
                    backgroundColor: "#DDDDDD", // Inactive tab indicator color
                  },
                },
              },
            }}
          >
            {services.map((service, index) => (
              <Tab
                key={index}
                label={
                  <Box sx={{ position: "relative" }}>
                    {service.label}
                    {service.comingSoon && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -70%) rotate(-12deg)",
                          width: "100px",
                          height: "20px",
                          borderRadius: 1,
                          backgroundColor: "rgba(255, 62, 165, 0.7)", // Adjust color and opacity
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#FFF700",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        Coming Soon
                      </Box>
                    )}
                  </Box>
                }
                disabled={service.comingSoon}
              />
            ))}
          </Tabs>
          <AnimatePresence mode="wait">
            <motion.div
              key={selectedService}
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={containerVariants}
              style={{ overflow: "hidden" }}
              layout
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" }, // Column on small screens, row on larger screens
                  justifyContent: "center",
                  alignItems: "top",
                  flexWrap: "wrap",
                }}
              >
                <Box sx={{ flex: 1, p: 3 }}>
                  <Typography variant="h5" component="h3" sx={{ mb: 2 }}>
                    {services[selectedService].label}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 3, textAlign: "justify" }}>
                    {services[selectedService].description}
                  </Typography>
                  {services[selectedService].features.map((feature, index) => (
                    <Typography
                      key={index}
                      variant="body2"
                      sx={{ display: "flex", alignItems: "center", mb: 1 }}
                    >
                      <CheckIcon color="primary" sx={{ mr: 1 }} />
                      {feature}
                    </Typography>
                  ))}
                </Box>
                <Box sx={{ flex: 1, p: 3 }}>
                  <img
                    src={services[selectedService].image}
                    alt={services[selectedService].label}
                    style={{ width: "100%", borderRadius: "8px" }}
                  />
                </Box>
              </Box>
            </motion.div>
          </AnimatePresence>
        </motion.div>
      </motion.div>
    </Box>
  );
};

export default ServiceTabs;
