import React from 'react';
import { Button, ButtonProps, styled } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const OutlinedStyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  // backgroundColor: 'white',
  color: 'white', // Brand primary color
  padding: '12px 2%',
  fontSize: '16px',
  boxSizing: "content-box",
  width: '155px',
  textTransform: 'none',
  borderRadius: '8px',
  border: '2px solid #FF3EA5', // Border with primary color
  '&:hover': {
    backgroundColor: '#FF3EA5',
    color: 'white',
    border: '2px solid white', // Border with primary color

  },
  '& .MuiButton-endIcon': {
    marginLeft: '8px',
  },
  '&:focus': {
    outline: 'none',
    boxShadow: 'none',
  },
}));

const OutlinedBrandButton: React.FC<ButtonProps> = (props) => {
  return (
    <OutlinedStyledButton  {...props}>
      {props.children}
    </OutlinedStyledButton>
  );
};

export default OutlinedBrandButton;