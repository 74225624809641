import {
  Container,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';


const faqs = [
  {
    question: "How often should I have my windows cleaned?",
    answer:
      "It depends on various factors, including your location and environmental conditions. Typically, we recommend window cleaning at least twice a year.",
  },
  {
    question: "Do you offer any guarantees for your services?",
    answer:
      "Yes, we offer a satisfaction guarantee for all our services. If you are not happy with the results, we will come back and address any issues at no extra cost.",
  },
  {
    question: "Are your cleaning products safe for the environment?",
    answer:
      "Yes, we use eco-friendly cleaning products that are safe for the environment and your home.",
  },
  {
    question: "Do I need to be home during the window cleaning service?",
    answer:
      "No, you do not need to be home during the service. However, we recommend being present if you have specific instructions or concerns.",
  },
  {
    question: "How long does the window cleaning process take?",
    answer:
      "The duration of the cleaning process depends on the size and number of windows. Typically, it takes a few hours to complete.",
  },
  {
    question: "What should I do to prepare for the window cleaning service?",
    answer:
      "Please ensure that windows are accessible and that any fragile items are removed from window sills. Our team will take care of the rest.",
  },
  {
    question: "Do you clean windows year-round?",
    answer:
      "Yes, we offer window cleaning services year-round, weather permitting. Some extreme weather conditions may require rescheduling.",
  },
  {
    question: "Do you clean both the inside and outside of windows?",
    answer:
      "Yes, we offer both interior and exterior window cleaning services to ensure your windows are spotless.",
  },
  {
    question: "Can you remove hard water stains from windows?",
    answer:
      "Yes, we can remove hard water stains and other tough grime from your windows using specialized cleaning techniques.",
  },
  {
    question: "How do I get a quote for your window cleaning services?",
    answer:
      "You can request a quote by contacting us through our website, by phone, or via email. We will provide you with a detailed estimate based on your specific needs.",
  },
];
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(255, 255, 255, .05)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    margin: 0,
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const FAQ = () => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  return (
    <Container id="faq" sx={{ my: 0 }}>
      <Typography
        variant="h4"
        component="h2"
        color="#FF3EA5"
        sx={{ mb: 2, textAlign: "center" }}
      >
        Need Answers? We’re Here to Help!
      </Typography>
      <Box>
        {faqs.map((faq, index) => (
            <Accordion key={index} expanded={expanded === 'panel'+ index} onChange={handleChange('panel'+ index)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography variant="h6" component="h2" color="#FF3EA5">
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" sx={{ textAlign: "left" }}>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
};

export default FAQ;
