import "./App.css";
import "./index.css";
import React, { useState, useEffect } from "react";
import Header from "./Components/Header";
import Services from "./Components/Services.tsx";
import BeforeAfter from "./Components/BeforeAfter";
import Reviews from "./Components/Reviews.tsx";
import FAQ from "./Components/FAQ.tsx";
import Contact from "./Components/Contact";
import HeroSection from "./Components/HeroSection.tsx";
import Footer from "./Components/Footer";
import SectionComponent from "./Components/SectionComponent.tsx";
import ParallaxSection from "./Components/ParallaxSection.tsx";
import "./styles.css";
import { CssBaseline, Container, Box, IconButton } from "@mui/material";
import { darkTheme, lightTheme } from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LayoutGroup } from "framer-motion";

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [theme, setTheme] = useState(prefersDarkMode ? darkTheme : lightTheme);
  const [isSticky, setSticky] = useState(window.scrollY > 80);

  const handleScroll = () => {
    setSticky(window.scrollY > 80);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setTheme(prefersDarkMode ? darkTheme : lightTheme);
  }, [prefersDarkMode]);

  const toggleTheme = () => {
    setTheme(theme.palette.mode === "light" ? darkTheme : lightTheme);
  };

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          className="fixed z-50"
          sx={{
            top: isSticky ? "80px" : "20px", // Adjust these values as needed
            right: "20px",
            transition: "top 0.3s ease",
          }}
        >
          <IconButton onClick={toggleTheme} color="inherit">
            {theme.palette.mode === "light" ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>
        </Box>
        <Header isSticky={isSticky} />
        <HeroSection />
        <Container component="main" sx={{ p: 0 }}>
          <SectionComponent />
          <Services />
          <BeforeAfter />
          <ParallaxSection />
          <Reviews />
          <FAQ />
          <Contact />
          <Footer />
        </Container>
      </ThemeProvider>
    </div>
  );
}

export default App;
