import React, { useState, useEffect } from 'react';
import { Parallax } from 'react-parallax';
import { Box, Typography, Button, useMediaQuery, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import backgroundImage from "./../Assets/img/house-3.webp";

const ParallaxSection = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const containerStyles = {
    width: `${windowWidth}px`,
    left: '50%',
    transform: 'translateX(-50%)',
  };

  const textVariants = {
    offscreen: {
      opacity: 0, 
      scale: 0
    },
    onscreen: {
      opacity: 1, 
      scale: 1,
      transition: {
        type: "ease",
        bounce: 0.4,
        duration: 0.7,
      }
    }
  };

  return (
    <Parallax bgImage={backgroundImage} strength={500} style={containerStyles}>
      <motion.div
        className="card-container"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: false, amount: 0.4 }}
      >
      <Box
        sx={{
          height: '400px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          color: 'white',
          backgroundColor: 'rgba(255,62,165, 0.7)', // Adjust the overlay color and opacity as needed
          width: '100%',
        }}
      >
        <motion.div className="card" variants={textVariants}>
          <Typography variant="h3" component="h1" sx={{ mb: 2, fontWeight: 'bold' }}>
            We Guarantee 100% Satisfaction
          </Typography>
        

          <Typography variant="h6" component="p" sx={{ mb: 4 }}>
            Try Austin&apos;s Best Exterior Cleaning Risk-Free Today!
          </Typography>

          <Button
            variant="contained"
            color="secondary"
            size="large"
            href="#contact"
            sx={{ fontWeight: 'bold' }}
          >
            Get A Fast Quote
          </Button>
          </motion.div>
      </Box>
        </motion.div>
    </Parallax>
  );
};

export default ParallaxSection;