import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Container,
  CircularProgress,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import PropTypes from "prop-types";
import { useField, useFormik, FormikProvider, Form } from "formik";
import * as Yup from "yup";
import CheckIcon from "@mui/icons-material/Check";
import { GoogleMap, useLoadScript, Autocomplete } from "@react-google-maps/api";
import InputMask from "react-input-mask";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { color } from "framer-motion";
import { stringify } from "postcss";

const AddressInput = ({ label, helpText, ...props }) => {
  const [field, meta] = useField(props);
  const [didFocus, setDidFocus] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [autocompleteRef, setAutocompleteRef] = useState(null);

  // Load Google Maps API for Places
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBKREoH_m5blv1HfP125XF018JdcvUQn7g", // Replace with your Google Maps API key
    libraries: ["places"],
  });

  useEffect(() => {
    if ((didFocus && field.value.trim().length > 0) || meta.touched) {
      setShowFeedback(true);
    }
  }, [didFocus, meta.touched, field.value, meta.error]);

  const handlePlaceSelect = () => {
    if (autocompleteRef && typeof autocompleteRef.getPlace === "function") {
      const place = autocompleteRef.getPlace();
      if (place && place.formatted_address) {
        field.onChange({
          target: { name: field.name, value: place.formatted_address },
        });
      }
    }
  };

  // if (loadError) {
  //   return <div>Error loading maps</div>;
  // }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ mb: 1 }}>
      <Typography variant="body1" component="label" htmlFor={props.id}>
        {label}
      </Typography>
      <Autocomplete
        onLoad={(ref) => setAutocompleteRef(ref)}
        onPlaceChanged={handlePlaceSelect}
        options={{
          bounds: {
            north: 30.594, // Approximate northern bound of Travis County
            south: 30.073, // Approximate southern bound of Travis County
            east: -97.315, // Approximate eastern bound of Travis County
            west: -98.041, // Approximate western bound of Travis County
          },
          // strictBounds: true,
          // componentRestrictions: { country: "us", administrativeArea: "TX" },
        }}
      >
        <TextField
          {...props}
          {...field}
          variant="outlined"
          fullWidth
          placeholder={helpText}
          aria-describedby={`${props.id}-feedback ${props.id}-help`}
          onFocus={() => setDidFocus(true)}
          error={showFeedback && Boolean(meta.error)}
          FormHelperTextProps={{
            sx: {
              color: showFeedback && (meta.error ? "#FF3EA5" : "#4CAF50"),
              textAlign: "center",
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderWidth: "2px",
                borderColor: showFeedback
                  ? meta.error
                    ? "#d32f2f"
                    : "#4CAF50"
                  : "#FF3EA5",
              },
              "&:hover fieldset": {
                borderColor: showFeedback
                  ? meta.error
                    ? "#d32f2f"
                    : "#4CAF50"
                  : "#FF3EA5",
              },
              "&.Mui-focused fieldset": {
                borderColor: showFeedback
                  ? meta.error
                    ? "#d32f2f"
                    : "#4CAF50"
                  : "#FF3EA5",
              },
            },
          }}
          helperText={
            <Typography
              variant="caption"
              component="div"
              className={`feedback ${showFeedback ? "" : "invisible"}`}
              sx={{ textAlign: "center" }}
            >
              {meta.error ? meta.error : "✓"}
            </Typography>
          }
        />
      </Autocomplete>
    </Box>
  );
};

const TextInputLiveFeedback = ({ label, helpText, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const [didFocus, setDidFocus] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  useEffect(() => {
    const value = field.value || "";
    if ((didFocus && value.trim().length > 0) || meta.touched) {
      setShowFeedback(true);
    }
  }, [didFocus, meta.touched, field.value, meta.error]);

  const handleChange = (event) => {
    let value = event.target.value;

    if (props.name === "phone") {
      // The value is already formatted by InputMask
      helpers.setValue(value);
    } else if (props.type === "number") {
      // Remove any non-digit characters
      value = value.replace(/\D/g, "");
      helpers.setValue(value);
    } else {
      helpers.setValue(value);
    }
  };

  return (
    <Box sx={{ mb: 1 }}>
      <Typography variant="body1" component="label" htmlFor={props.id}>
        {label}
      </Typography>
      {props.name === "phone" ? (
        <InputMask
          mask="(999) 999-9999"
          value={field.value}
          onChange={handleChange}
          onFocus={() => setDidFocus(true)}
        >
          {() => (
            <TextField
              {...props}
              variant="outlined"
              fullWidth
              placeholder={helpText}
              aria-describedby={`${props.id}-feedback ${props.id}-help`}
              error={showFeedback && Boolean(meta.error)}
              FormHelperTextProps={{
                sx: {
                  color: showFeedback && (meta.error ? "#FF3EA5" : "#4CAF50"),
                  textAlign: "center",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderWidth: "2px",
                    borderColor: showFeedback
                      ? meta.error
                        ? "#d32f2f"
                        : "#4CAF50"
                      : "#FF3EA5",
                  },
                  "&:hover fieldset": {
                    borderColor: showFeedback
                      ? meta.error
                        ? "#d32f2f"
                        : "#4CAF50"
                      : "#FF3EA5",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: showFeedback
                      ? meta.error
                        ? "#d32f2f"
                        : "#4CAF50"
                      : "#FF3EA5",
                  },
                },
              }}
              helperText={
                <Typography
                  variant="caption"
                  component="div"
                  className={`feedback ${showFeedback ? "" : "invisible"}`}
                  sx={{ textAlign: "center" }}
                >
                  {meta.error ? meta.error : "✓"}
                </Typography>
              }
            />
          )}
        </InputMask>
      ) : (
        <TextField
          {...props}
          {...field}
          variant="outlined"
          fullWidth
          multiline={props.type === "textarea"}
          rows={props.type === "textarea" ? 4 : undefined}
          placeholder={helpText}
          aria-describedby={`${props.id}-feedback ${props.id}-help`}
          onFocus={() => setDidFocus(true)}
          onChange={handleChange}
          error={showFeedback && Boolean(meta.error)}
          FormHelperTextProps={{
            sx: {
              color: showFeedback && (meta.error ? "#FF3EA5" : "#4CAF50"),
              textAlign: "center",
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderWidth: "2px",
                borderColor: showFeedback
                  ? meta.error
                    ? "#d32f2f"
                    : "#4CAF50"
                  : "#FF3EA5",
              },
              "&:hover fieldset": {
                borderColor: showFeedback
                  ? meta.error
                    ? "#d32f2f"
                    : "#4CAF50"
                  : "#FF3EA5",
              },
              "&.Mui-focused fieldset": {
                borderColor: showFeedback
                  ? meta.error
                    ? "#d32f2f"
                    : "#4CAF50"
                  : "#FF3EA5",
              },
            },
            ...(props.type === "textarea" && {
              mb: 0,
            }),
          }}
          helperText={
            <Typography
              variant="caption"
              component="div"
              className={`feedback ${showFeedback ? "" : "invisible"}`}
              sx={{ textAlign: "center" }}
            >
              {meta.error ? meta.error : "✓"}
            </Typography>
          }
          inputProps={props.inputProps}
        />
      )}
    </Box>
  );
};

TextInputLiveFeedback.propTypes = {
  label: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  inputProps: PropTypes.object,
  name: PropTypes.string.isRequired,
};

AddressInput.propTypes = {
  label: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
};

const DateInputLiveFeedback = ({ label, helpText, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const [didFocus, setDidFocus] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  useEffect(() => {
    // if (JSON.stringify(field.value) === "null") {
    //   setShowFeedback(false);
    // } else
    if ((didFocus && JSON.stringify(field.value) !== "null") || meta.touched) {
      setShowFeedback(true);
    }
  }, [didFocus, meta.touched, field.value, meta.error]);

  return (
    <Box sx={{ mb: 1, display: "flex", flexDirection: "column" }}>
      <Typography variant="body1" component="label" htmlFor={props.id}>
        {label}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          // label={helpText}
          // onFocus={() => setDidFocus(true)}
          value={field.value}
          minDate={dayjs()}
          onChange={(value) => {
            helpers.setValue(value);
            setDidFocus(true);
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderWidth: "2px",
                borderColor: showFeedback
                  ? meta.error
                    ? "#d32f2f"
                    : "#4CAF50"
                  : "#FF3EA5",
              },
              "&:hover fieldset": {
                borderColor: showFeedback
                  ? meta.error
                    ? "#d32f2f"
                    : "#4CAF50"
                  : "#FF3EA5",
              },
              "&.Mui-focused fieldset": {
                borderColor: showFeedback
                  ? meta.error
                    ? "#d32f2f"
                    : "#4CAF50"
                  : "#FF3EA5",
              },
            },
            "& .MuiFormHelperText-root": {
              textAlign: "center",
              visibility:
                JSON.stringify(field.value) === "null" ? "hidden" : "visible",
              whiteSpace: "nowrap",
            },
          }}
          slotProps={{
            textField: {
              helperText: showFeedback && meta.error ? meta.error : "✓",
            },
            actionBar: {
              actions: ["clear", "accept"],
            },
            // The actions will be different between desktop and mobile
            // actionBar: ({ wrapperVariant }) => ({
            //   actions: wrapperVariant === 'desktop' ? [] : ['clear'],
            // }),
          }}
          // renderInput={(params) => (
          //   <TextField
          //     {...params}
          //     variant="outlined"
          //     fullWidth
          //     onFocus={() => setDidFocus(true)}
          //     error={showFeedback && Boolean(meta.error)}
          //     FormHelperTextProps={{
          //       sx: {
          //         color: showFeedback && (meta.error ? "#FF3EA5" : "#4CAF50"),
          //         textAlign: "center",
          //       },
          //     }}
          //     sx={{
          //   "& .MuiOutlinedInput-root": {
          //     "& fieldset": {
          //       borderWidth: "2px",
          //       borderColor: showFeedback
          //         ? meta.error
          //           ? "#d32f2f"
          //           : "#4CAF50"
          //         : "#FF3EA5",
          //     },
          //     "&:hover fieldset": {
          //       borderColor: showFeedback
          //         ? meta.error
          //           ? "#d32f2f"
          //           : "#4CAF50"
          //         : "#FF3EA5",
          //     },
          //     "&.Mui-focused fieldset": {
          //       borderColor: showFeedback
          //         ? meta.error
          //           ? "#d32f2f"
          //           : "#4CAF50"
          //         : "#FF3EA5",
          //     },
          //   },
          // }}
          //     helperText={
          //       <Typography
          //         variant="caption"
          //         component="div"
          //         className={`feedback ${showFeedback ? "" : "invisible"}`}
          //         sx={{ textAlign: "center" }}
          //       >
          //         {meta.error ? meta.error : "✓"}
          //       </Typography>
          //     }
          //   />
          // )}
        />
      </LocalizationProvider>
    </Box>
  );
};

DateInputLiveFeedback.propTypes = {
  label: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const Contact = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [containerHeight, setContainerHeight] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.offsetHeight);
      console.log("height", containerRef.current.offsetHeight);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      address: "",
      phone: "",
      propertyType: "Residential",
      numberOfStories: "",
      numberOfWindows: "",
      preferredDate: null,
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
      email: Yup.string().email("Invalid email").required("Required"),
      address: Yup.string(),
      phone: Yup.string()
        .matches(
          /^\(\d{3}\) \d{3}-\d{4}$/,
          "Phone number is not valid. Format: (123) 456-7890"
        )
        .required("Required"),
      propertyType: Yup.string().required("Required"),
      numberOfStories: Yup.number()
        .min(1, "Must be at least 1 story")
        .max(100, "That seems to tall")
        .required("Required"),
      numberOfWindows: Yup.number()
        .min(1, "Must have at least 1 window")
        .max(1000, "That seems like too many")
        .required("Required"),
      preferredDate: Yup.date()
        .required("Required")
        .min(dayjs(), "Date must be after today"),
      message: Yup.string(),
      // .min(10, "Must be at least 10 characters!")
      // .required("Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      setErrorMessage("");
      await sleep(500);

      try {
        const response = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            access_key: "acbca84d-2890-4543-8e53-44547bab6ac1",
            ...values,
          }),
        });

        if (response.ok) {
          setFormSubmitted(true);
          resetForm();
        } else {
          throw new Error("Form submission failed");
        }
      } catch (error) {
        setErrorMessage(error.message);
        setFormSubmitted(true);
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <Container id="contact" sx={{ my: 4, textAlign: "center" }}>
      <Typography variant="h4" component="h2" color="#FF3EA5" sx={{ mb: 1 }}>
        Contact Us
      </Typography>
      <Typography variant="body1">
        Phone:{" "}
        <a href="tel:+14095434333" style={{ color: "#FF3EA5" }}>
          (409) 543-4333
        </a>
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        Email:{" "}
        <a href="mailto:contact@quackwash.com" style={{ color: "#FF3EA5" }}>
          contact@quackwash.com
        </a>
      </Typography>
      <FormikProvider value={formik}>
        <Box className="card cardForm">
          {formSubmitted ? (
            errorMessage ? (
              <Box
                className="formError"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: `${containerHeight}px`,
                }}
              >
                <Box className="errorIcon" sx={{ fontSize: 30 }}>
                  😖
                </Box>
                <Typography variant="h5" sx={{ marginBlockEnd: 1 }}>
                  Yikes! An error occurred.
                </Typography>
                <Typography variant="h6" sx={{ marginBlockEnd: 2 }}>
                  Error: {errorMessage}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setFormSubmitted(false);
                    setErrorMessage("");
                  }}
                >
                  Try Again
                </Button>
              </Box>
            ) : (
              <Box
                className="formSuccess"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: `${containerHeight}px`,
                }}
              >
                <Typography variant="h5" className="thankYouMessage">
                  Thank You!
                </Typography>
                <Box className="successMessage">
                  <Typography variant="h6">
                    The form has been submitted!
                  </Typography>
                  <CheckIcon
                    color="success"
                    fontSize="large"
                    sx={{ marginBlockEnd: 2 }}
                  />
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setFormSubmitted(false)}
                >
                  Submit Another Form
                </Button>
              </Box>
            )
          ) : isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: `${containerHeight}px`,
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Form className="formBody" ref={containerRef}>
              <Grid container rowSpacing={0} columnSpacing={2}>
                <Grid item xs={12}>
                  <TextInputLiveFeedback
                    label="Name"
                    id="name"
                    name="name"
                    type="text"
                    helpText="Please enter your full name"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInputLiveFeedback
                    label="Email"
                    id="email"
                    name="email"
                    type="email"
                    helpText="We'll never share your email with anyone else"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInputLiveFeedback
                    label="Phone Number"
                    id="phone"
                    name="phone"
                    type="tel"
                    helpText="Please enter your phone number"
                  />
                </Grid>
                <Grid item xs={12}>
                  <AddressInput
                    label="Address"
                    id="address"
                    name="address"
                    type="address"
                    helpText="Please enter your address (Not required)"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInputLiveFeedback
                    label="Number of Stories"
                    id="numberOfStories"
                    name="numberOfStories"
                    type="number"
                    helpText="Please enter the number of stories of your property"
                    inputProps={{ inputMode: "numeric", min: 1, max: 100 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInputLiveFeedback
                    label="Number of Windows"
                    id="numberOfWindows"
                    name="numberOfWindows"
                    type="number"
                    helpText="Please enter the number of windows to be cleaned"
                    inputProps={{ inputMode: "numeric", min: 1, max: 1000 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateInputLiveFeedback
                    label="Preferred Cleaning Date"
                    id="preferredDate"
                    name="preferredDate"
                    helpText="Preferred date for cleaning"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* Use radio buttons for Property Type */}
                  <FormControl
                    component="fieldset"
                    sx={{
                      mb: 1,
                    }}
                  >
                    <Typography
                      variant="body1"
                      component="label"
                      htmlFor="propertyType"
                      sx={{
                        mb: 1,
                      }}
                    >
                      Property Type
                    </Typography>
                    <RadioGroup
                      row
                      aria-label="propertyType"
                      defaultValue="Residential"
                      name="propertyType"
                      value={formik.values.propertyType}
                      onChange={formik.handleChange}
                    >
                      <FormControlLabel
                        value="Residential"
                        control={<Radio />}
                        label="Residential"
                      />
                      <FormControlLabel
                        value="Commercial"
                        control={<Radio />}
                        label="Commercial"
                      />
                    </RadioGroup>
                    {formik.touched.propertyType &&
                      formik.errors.propertyType && (
                        <Typography
                          variant="caption"
                          color="error"
                          sx={{ textAlign: "center" }}
                        >
                          {formik.errors.propertyType}
                        </Typography>
                      )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextInputLiveFeedback
                    label="Message"
                    id="message"
                    name="message"
                    type="textarea"
                    helpText="Please enter any additional information"
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                variant="contained"
                sx={{ backgroundColor: "#FF3EA5", color: "white", mt: 2 }}
              >
                Submit
              </Button>
            </Form>
          )}
        </Box>
      </FormikProvider>
    </Container>
  );
};

export default Contact;
