import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { motion, AnimatePresence } from "framer-motion";
import duckLogo from "./../Assets/img/logo-1.png";
import QuoteButton from "./Reusable/QuoteButton.tsx";
import "./Header.css";
import { useTheme } from "@mui/material/styles";

const navItems = ["Services", "Gallery", "Reviews", "FAQ", "Contact"];

const Header = ({ isSticky }) => {
  // const [isSticky, setSticky] = useState(
  //   window.scrollY > window.innerHeight * 0.08
  // );
  const [mobileOpen, setMobileOpen] = useState(false);
  const headerRef = useRef(null);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("sm"));

  // const handleScroll = () => {
  //   const threshold = window.innerHeight * 0.08;
  //   setSticky(window.scrollY > threshold);
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll, { passive: true });
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center" }}
      className="flex flex-col h-full w-full justify-between items-center"
    >
      <Box
        onClick={handleDrawerToggle}
        sx={{ textAlign: "center" }}
        className="w-full"
      >
        <Typography variant="h6" sx={{ my: 2 }}>
          QuackWash
        </Typography>
        <Divider />
        <List>
          {navItems.map((item) => (
            <ListItem key={item} disablePadding>
              <ListItemButton
                href={`#${item.toLowerCase()}`}
                sx={{ textAlign: "center" }}
              >
                <ListItemText primary={item} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      <QuoteButton sx={{ m: 2, display: { xs: "block", md: "block" } }}>
        Fast Quote
      </QuoteButton>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AnimatePresence>
        <AppBar
          component="nav"
          ref={headerRef}
          className={isSticky ? "sticky" : "initial"}
          sx={{
            backgroundColor: isSticky
              ? theme.palette.primary.main
              : theme.palette.background.default,
            color: isSticky
              ? theme.palette.common.white
              : theme.palette.text.primary,
            transition: "0.3s ease-in",
            alignItems: isSticky ? null : isMdUp ? null : "center",
          }}
        >
          <Toolbar className="flex justify-between">
            {(isMdUp || isSticky) && (
              <motion.img
                key="logo"
                src={duckLogo}
                alt="QuackWash Logo"
                initial={{ opacity: 0, height: "80px", width: "80px" }}
                animate={{
                  opacity: 1,
                  height: isSticky ? "70px" : isMdUp ? "80px" : "80px",
                  width: isSticky ? "70px" : isMdUp ? "80px" : "80px",
                }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                style={{
                  marginRight: "10px",
                }}
              />
            )}
            <Box
              component={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              {navItems.map((item) => (
                <Button
                  key={item}
                  color="inherit"
                  href={`#${item.toLowerCase()}`}
                >
                  <Typography sx={{ fontWeight: "bold" }}>{item}</Typography>
                </Button>
              ))}
            </Box>
            <div className="flex flex-row">
              <QuoteButton
                // component={motion.div}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                sx={{ m: 1, display: { xs: "block", sm: "block" } }}
              >
                Fast Quote
              </QuoteButton>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ m: 0, display: { md: "none" } }}
              >
                <MenuIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </AnimatePresence>
      <Box component="nav">
        <Drawer
          anchor="right"
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

Header.propTypes = {
  isSticky: PropTypes.bool.isRequired,
};

export default Header;
