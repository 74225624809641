import React, { useState, useRef } from "react";
import {
  Container,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import before1 from "./../Assets/img/1-before.webp";
import after1 from "./../Assets/img/1-after.webp";
import before2 from "./../Assets/img/2-before.webp";
import after2 from "./../Assets/img/2-after.webp";
import {
  reviewVariants,
  reviewContainer,
  containerVariants,
  cardVariants,
  container,
} from "./Reusable/FramerMotionVariants.tsx";
import { motion, Variants, AnimatePresence } from "framer-motion";

import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";

export default function BeforeAfter() {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [sliderPosition1, setSliderPosition1] = useState(50);
  const [sliderPosition2, setSliderPosition2] = useState(50);
  const sliderRef1 = useRef(null);
  const sliderRef2 = useRef(null);

  const handleMouseDown = (event, sliderRef, setSliderPosition) => {
    const onMouseMove = (moveEvent) => {
      const slider = sliderRef.current.getBoundingClientRect();
      const position = ((moveEvent.clientX - slider.left) / slider.width) * 100;
      setSliderPosition(Math.min(Math.max(position, 0), 100));
    };

    const onMouseUp = () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
  };

  return (
    <Container id="gallery" sx={{ my: 2, textAlign: "center" }}>
      <motion.div
        className="card-container"
        variants={container}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.4 }}
      >
        <motion.div className="card" variants={cardVariants}>
          <Typography
            variant="h4"
            component="h2"
            color="#FF3EA5"
            sx={{ mb: 2 }}
          >
            Before & After
          </Typography>
        </motion.div>
        <motion.div className="card" variants={cardVariants}>
          <Box
            component="div"
            display="flex"
            justifyContent="center"
            alignItems="start"
            className=" flex-wrap flex-col md:flex-row"
          >
            <ReactCompareSlider
              className="flex flex-1 w-full md:mr-2 mb-2 rounded-lg"
              portrait={false}
              itemOne={
                <ReactCompareSliderImage
                  src={before1}
                  alt="Dirty window before cleaning"
                />
              }
              itemTwo={
                <ReactCompareSliderImage
                  src={after1}
                  alt="Clean window after QuackWash cleaning"
                />
              }
            />
            <ReactCompareSlider
              className="flex flex-1 w-full md:ml-2 mb-2 rounded-lg"
              portrait={false}
              itemOne={
                <ReactCompareSliderImage
                  src={before2}
                  alt="Dirty window before cleaning"
                />
              }
              itemTwo={
                <ReactCompareSliderImage
                  src={after2}
                  alt="Clean window after QuackWash cleaning"
                />
              }
            />
          </Box>
        </motion.div>
      </motion.div>
    </Container>
  );
}
