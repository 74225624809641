import { Variants } from "framer-motion";

export const container: Variants = {
  offscreen: { opacity: 1 },
  onscreen: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
}

export const containerVariants: Variants = {
  hidden: { opacity: 0, y: 10 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "ease",
      duration: 2,

      // stiffness: 100,
      // damping: 10,
    },
  },
  exit: { opacity: 0, y: -10 },
};

export const cardVariants: Variants = {
  offscreen: {
    y: 150,
    // visibility: "hidden",
    opacity: 0
  },
  onscreen: {
    y: 0,
    // visibility: "visible",
    opacity: 1,
    // rotate: -10,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 1.5,
    }
  }
};

export const reviewContainer: Variants = {
  offscreen: { opacity: 0 },
  onscreen: {
    opacity: 1,
    // transition: {
    //   staggerChildren: 0.3
    // }
  }
}

export const reviewVariants: Variants = {
  offscreen: {
    opacity: 0
  },
  onscreen: {
    opacity: 1,
    transition: {
      // type: "spring",
      // bounce: 0.4,
      delay: 0.5,
      duration: 0.4
    }
  }
};