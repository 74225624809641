import React, { useRef } from "react";
import { Container, Typography, Box, Button, useMediaQuery, useTheme } from "@mui/material";
import backgroundImage from "./../Assets/img/house.webp";
import "./HeroSection.css";
import { Slide, Fade } from "@mui/material";
import QuoteButton from "./Reusable/QuoteButton.tsx";
import OutlinedButton from "./Reusable/OutlinedButton.tsx";
import { motion, Variants, AnimatePresence } from "framer-motion";
import CallIcon from '@mui/icons-material/Call';


const container: Variants = {
  offscreen: { opacity: 0 },
  onscreen: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      delay: 0.2,
      // delay: 1 
    }
  }
}

const cardVariants: Variants = {
  offscreen: {
    y: 150,
    // visibility: "hidden",
    opacity: 0
  },
  onscreen: {
    y: 0,
    // visibility: "visible",
    opacity: 1,
    // rotate: -10,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8
      // delay: 0.2
    }
  }
};

const HeroSection = () => {
  const sectionRef = useRef<HTMLElement>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const typographyVariant = isMdUp ? "h3" : "h4";
  const secondTypographyVariant = isMdUp ? "h4" : "h5";

  return (
    <Box
      className="hero-section"
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: isSmallScreen ? "end" : "center",
        paddingBottom: isSmallScreen ? "100px" : "0",
        color: "white",
        textAlign: "center",
        overflow: "hidden",
        transition: "all 0.5s ease",
      }}
    >
      
      <motion.div
        className="card-container"
        variants={container}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: false, amount: 0.5 }}
      >
        <Container ref={sectionRef}>
        <Typography variant={secondTypographyVariant} component="h2" sx={{ mb: 3, display: "none" }}>
              Top rated. Best rated. Austin, TX. Travis County. Five Star Reviews.
            </Typography>
          <motion.div className="card" variants={cardVariants} >
            <Typography variant={typographyVariant} component="h1" sx={{ mb: 2 }}>
              <span className="highlight">Window Cleaning</span> That Makes Your Home Shine!
            </Typography>
          </motion.div>

          <motion.div className="card" variants={cardVariants} >
            <Typography variant={secondTypographyVariant} component="p" sx={{ mb: 3 }}>
              Restore your home’s charm with our expert window cleaning!
            </Typography>
          </motion.div>

          <motion.div className="card" variants={cardVariants}>
            <Box>
              {/* <QuoteButton sx={{ mx: 1, mb:2 }}>Get A Fast Quote</QuoteButton> */}
              <OutlinedButton sx={{ mx: 1, mb:2 }} href="#contact">
              Get A Fast Quote
              </OutlinedButton>
              <OutlinedButton startIcon={<CallIcon />} sx={{ mx: 1, mb:2 }} href="tel:+14095434333">
                (409) 543-4333
              </OutlinedButton>
            </Box>
          </motion.div>
        </Container>
      </motion.div>
    </Box>
  );
};

export default HeroSection;