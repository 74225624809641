import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

const Footer = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        bgcolor: "#FFFB00",
        color: "#FF3EA5",
        textAlign: "center",
        // py: 2,
        position: "absolute",
        left: 0,
        width: windowWidth,
      }}
    >
      <Typography variant="body1">
        &copy; 2024 QuackWash. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
